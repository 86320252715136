import { jsPDF } from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import moment from "moment";
import eatprestoLogo from "../../../../../assets/images/logos/logo.png";
import { ExportPdfColor } from "../../../../../root/theme/exportColor";

const handleNodeAndFooterLength = (key: any, nodes: any) => {
  let nodeLength = 0;
  if (key === "cancelled-receipts") {
    nodeLength = nodes["cancelledSaleNodesLength"];
  } else if (key === "void-transaction") {
    nodeLength = nodes["voidTransactionNodesLength"];
  } else if (key === "paid-in-out") {
    nodeLength = nodes["paidInOutNodesLength"];
  } else {
    nodeLength = 0;
  }
  return { nodeLength };
};

const handleConvertPdf = (doc: any, filterDetails: any) => {
  const locationFilter = filterDetails.split("*")[0];
  const pageWidth = doc.internal.pageSize.getWidth();
  /* Create a pdf file. */
  doc.autoTable({
    // Add topic and sub topics.
    didDrawPage: function (data: any) {
      // Change topic font size
      doc.setFontSize(12);
      doc.text("Presto Express Solutions", data.settings.margin.left, 12);
      // Modify the marginLeft according to the scape.
      doc.text("Shift Summary Report", data.settings.margin.left + 140, 12);
      // Change main title font size
      doc.setFontSize(16);
      // Insert a topic for the first page only.
      if (doc.internal.getNumberOfPages() === 1) {
        /* Change the title according to the number of locations.
        If the location size is increased, show presto express. if the location size is the one, show that location. */
        doc.setTextColor("#32363E");
        if (locationFilter.includes(",")) {
        } else {
          doc.text(
            filterDetails
              .split("*")[0]
              .replace("Shift summary report for location/s:", ""),
            pageWidth / 2,
            36,
            {
              align: "center",
            },
          );
        }

        doc.setTextColor(0, 0, 0);
        doc.text("Shift Summary Report", pageWidth / 2, 56, "center");
        doc.setFontSize(10);

        /* Change the sub title according to the number of locations.
        If the location size is increased, show number of locations. if the location size is the one, show that location. */
        let filterLocationsDetails = "";
        if (locationFilter.split(",").length === 1) {
          filterLocationsDetails =
            "Shift Summary Report for " + locationFilter.split(",")[0];
        } else {
          filterLocationsDetails =
            "Shift Summary Report for " +
            locationFilter.split(",").length +
            " Locations";
        }

        doc.text(
          doc.splitTextToSize(filterLocationsDetails, 210 - 15 - 15),
          pageWidth / 2,
          44,
          {
            align: "center",
          },
        );
      }
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });
};

const rowHeight = (tableLength: any) => {
  console.log("azzzzzzzzzzzzsdasd", tableLength)
  if (tableLength === 7) {
    return 11.8;
  } else if (tableLength === 8) {
    return 8.5;
  } else if (tableLength === 9) {
    return 12.8;
  } else if (tableLength === 10) {
    return 11.1;
  } else if (tableLength === 11) {
    return 9.5;
  } else if (tableLength === 12) {
    return 7.8;
  } else if (tableLength === 13) {
    return 11.3;
  } else if (tableLength === 14) {
    return 9.7;
  } else if (tableLength === 15) {
    return 8;
  } else if (tableLength === 16) {
    return 9.8;
  } else if (tableLength === 17) {
    return 8.1;
  } else if (tableLength === 18) {
    return 10;
  } else if (tableLength === 19) {
    return 8.2;
  } else if (tableLength === 20) {
    return 10;
  } else if (tableLength === 21) {
    return 8.4;
  }
};

const handleTableInfo = (
  key: any,
  doc: any,
  pdfHeader: any,
  nodes: any,
  startY: any,
  lastIndex: any,
  nodeLength: any,
  tableLength: any,
) => {
  console.log("Asdasfsdfsdgfdgfd 1111111111", startY)

  const columnLength: number = pdfHeader.length;
  const totalPagesExp = "{total_pages_count_string}";
  /* Create a new table. */
  doc.autoTable({
    columns: pdfHeader.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    showHead: "never",
    startY: startY,
    tableWidth: 90,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
      minCellHeight: 12,
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: {
        fontStyle: "bold",
        halign: "left",
        columnWidth: 90 / columnLength,
      },
      1: { halign: "right", columnWidth: 90 / columnLength },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,

    /* Change the background color, font weight and alignment in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          if (data.cell.raw === "Currency") {
            data.cell.text = [""];
          }
        } else {
          data.cell.styles.halign = "right";
        }
      }
      if (data.column.index === lastIndex) {
        data.cell.styles.halign = "right";
      }
      if (data.row.raw.type==="Deficit / Surplus"&& data.row.raw.amount === 0) {
        data.cell.styles.fillColor = [ExportPdfColor.blueDark];
        data.cell.styles.textColor = ["#ffffff"];
      } else if (data.row.raw.type==="Deficit / Surplus"&&data.row.raw.amount > 0) {
        data.cell.styles.fillColor = [ExportPdfColor.green];
        data.cell.styles.textColor = ["#ffffff"];
      } else if (data.row.raw.type==="Deficit / Surplus"&&data.row.raw.amount < 0) {
        data.cell.styles.fillColor = [ExportPdfColor.red];
        data.cell.styles.textColor = ["#ffffff"];
      } else {
        data.cell.styles.fillColor = [ExportPdfColor.blueDark];
        data.cell.styles.textColor = ["#ffffff"];
      }
      if (
        data.row.index + 1 === rows.length &&
        key !== "Breakdown of Shifts" &&
        key !== "Pay In Out" &&
        key !== "Summary"
      ) {
        data.cell.styles.fontStyle = "bold";
      }
    },
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();

      /* If there is a image, insert it into the eatprestoLogoImg variable. */
      const eatprestoLogoImg = new Image();
      eatprestoLogoImg.src = eatprestoLogo;
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);

      doc.addImage(
        eatprestoLogoImg,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    margin: {
      top: 30,
      bottom: 30,
      // left: 110,
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
};

const handleTableInfoTaking = (
  key: any,
  doc: any,
  pdfHeader: any,
  nodes: any,
  startY: any,
  lastIndex: any,
  nodeLength: any,
  tableLength: any,
) => {
  console.log("Asdasfsdfsdgfdgfd 2222", startY)
  const columnLength: number = pdfHeader.length;
  const totalPagesExp = "{total_pages_count_string}";
  /* Create a new table. */
  doc.autoTable({
    columns: pdfHeader.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    showHead: "never",
    startY: startY,
    tableWidth: 90,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
      minCellHeight: 8,
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: {
        fontStyle: "bold",
        halign: "left",
        columnWidth: 90 / columnLength,
      },
      1: { halign: "right", columnWidth: 90 / columnLength },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,

    /* Change the background color, font weight and alignment in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          if (data.cell.raw === "Currency") {
            data.cell.text = [""];
          }
        } else {
          data.cell.styles.halign = "right";
        }
      }
      if (data.column.index === lastIndex) {
        data.cell.styles.halign = "right";
      }
      if(data.row.index+1 === nodes.length) {
        data.cell.styles.fillColor = [ExportPdfColor.green];
        data.cell.styles.textColor = ["#ffffff"];
      } else if (data.row.index % 2 === 0) {
        data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
        data.cell.styles.textColor = ["#ffffff"];
      } else {
        data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
        data.cell.styles.textColor = ["#ffffff"];
      }
      if (
        data.row.index + 1 === rows.length &&
        key !== "Breakdown of Shifts" &&
        key !== "Pay In Out" &&
        key !== "Summary"
      ) {
        data.cell.styles.fontStyle = "bold";
      }
    },
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();

      /* If there is a image, insert it into the eatprestoLogoImg variable. */
      const eatprestoLogoImg = new Image();
      eatprestoLogoImg.src = eatprestoLogo;
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);

      doc.addImage(
        eatprestoLogoImg,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    margin: {
      top: 30,
      bottom: 30,
      left: 110,
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
};

const handleTableCash = (
  key: any,
  doc: any,
  pdfHeader: any,
  nodes: any,
  startY: any,
  lastIndex: any,
  nodeLength: any,
  isCashDeducted: any,
) => {
  const columnLength: number = pdfHeader.length;
  const pageWidth = doc.internal.pageSize.getWidth();
  const totalPagesExp = "{total_pages_count_string}";

  /* Create a new table. */
  doc.autoTable({
    columns: pdfHeader.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    showHead: key === "Summary" || nodeLength <= 1 ? "never" : "everyPage",
    startY: startY,
    tableWidth: 90,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: {
        fontStyle: "bold",
        halign: "left",
        columnWidth: 90 / columnLength,
      },
      1: { halign: "right", columnWidth: 90 / columnLength },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,

    /* Use the green footer only for other tables, as there is no footer for a 
    breakdown of shifts, banking, and summary tables. */
    willDrawCell: function (data: any, index: any) {
      if (key !== "Breakdown of Shifts" && key !== "Summary") {
        const rows = data.table.body;
        if (data.row.index === rows.length - 1 && data.row.section !== "head") {
          if (isCashDeducted) {
            doc.setFillColor(ExportPdfColor.red);
          } else {
            doc.setFillColor(ExportPdfColor.green);
          }
          doc.setTextColor(255, 255, 255);
        }
      }
    },
    /* Change the background color, font weight and alignment in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          if (data.cell.raw === "Currency") {
            data.cell.text = [""];
          }
        } else {
          data.cell.styles.halign = "right";
        }
      }
      if (data.column.index === lastIndex) {
        data.cell.styles.halign = "right";
      }
      if (data.row.index !== 0) {
        if (nodeLength > 0) {
          if (data.row.index < nodeLength) {
            if (data.row.index % 2 === 0) {
              data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
              data.cell.styles.textColor = ["#ffffff"];
            } else {
              data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
              data.cell.styles.textColor = ["#ffffff"];
            }
          } else {
            if (
              data.row.raw.Date === "Pay In" ||
              data.row.raw.Date === "Pay Out"
            ) {
              data.cell.styles.fillColor = [ExportPdfColor.purple];
              data.cell.styles.textColor = ["#ffffff"];
              data.cell.styles.fontStyle = "bold";
            } else {
              data.cell.styles.fillColor = [ExportPdfColor.blueDark];
              data.cell.styles.textColor = ["#ffffff"];
              data.cell.styles.fontStyle = "bold";
            }
          }
        } else {
          if (data.row.index % 2 === 0) {
            data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          } else {
            data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          }
        }
      }
      if (
        data.row.index + 1 === rows.length &&
        key !== "Breakdown of Shifts" &&
        key !== "Pay In Out" &&
        key !== "Summary"
      ) {
        data.cell.styles.fontStyle = "bold";
      }
    },
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();

      /* If there is a image, insert it into the eatprestoLogoImg variable. */
      const eatprestoLogoImg = new Image();
      eatprestoLogoImg.src = eatprestoLogo;
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);

      doc.addImage(
        eatprestoLogoImg,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    margin: {
      top: 30,
      bottom: 30,
      left: 110,
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
};

const handleTable = (
  key: any,
  doc: any,
  pdfHeader: any,
  nodes: any,
  startY: any,
  lastIndex: any,
  nodeLength: any,
) => {
  const columnLength: number = pdfHeader.length;
  const pageWidth = doc.internal.pageSize.getWidth();
  const totalPagesExp = "{total_pages_count_string}";

  /* Create a new table. */
  doc.autoTable({
    columns: pdfHeader.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    showHead: key === "Summary" ? "never" : "everyPage",
    startY: startY,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: {
        fontStyle: "bold",
        halign: "left",
        columnWidth: (pageWidth - 28.2) / columnLength,
      },
      1: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      2: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      3: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      4: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      5: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      6: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,

    /* Use the green footer only for other tables, as there is no footer for a 
    breakdown of shifts, banking, and summary tables. */
    willDrawCell: function (data: any, index: any) {
      if (key !== "Breakdown of Shifts" && key !== "Summary") {
        const rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          doc.setFillColor(ExportPdfColor.green);
          doc.setTextColor(255, 255, 255);
        }
      }
    },
    /* Change the background color, font weight and alignment in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          if (data.cell.raw === "Currency") {
            data.cell.text = [""];
          }
        }
      }
      if (data.column.index === lastIndex) {
        data.cell.styles.halign = "right";
      }
      if (data.row.index !== 0) {
        if (nodeLength > 0) {
          if (data.row.index < nodeLength) {
            if (data.row.index % 2 === 0) {
              data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
              data.cell.styles.textColor = ["#ffffff"];
            } else {
              data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
              data.cell.styles.textColor = ["#ffffff"];
            }
          } else {
            if (
              data.row.raw.Date === "Pay In" ||
              data.row.raw.Date === "Pay Out"
            ) {
              data.cell.styles.fillColor = [ExportPdfColor.purple];
              data.cell.styles.textColor = ["#ffffff"];
              data.cell.styles.fontStyle = "bold";
            } else {
              data.cell.styles.fillColor = [ExportPdfColor.blueDark];
              data.cell.styles.textColor = ["#ffffff"];
              data.cell.styles.fontStyle = "bold";
            }
          }
        } else {
          if (data.row.index % 2 === 0) {
            data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          } else {
            data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          }
        }
      }
      if (
        data.row.index + 1 === rows.length &&
        key !== "Breakdown of Shifts" &&
        key !== "Pay In Out" &&
        key !== "Summary"
      ) {
        data.cell.styles.fontStyle = "bold";
      }
    },
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();

      /* If there is a image, insert it into the eatprestoLogoImg variable. */
      const eatprestoLogoImg = new Image();
      eatprestoLogoImg.src = eatprestoLogo;
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);

      doc.addImage(
        eatprestoLogoImg,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
};

const bankingCellHeight = (tableLength: any) => {
  if (tableLength > 19) {
    return 13;
  }
  if (tableLength > 17) {
    return 12;
  }
  if (tableLength > 15) {
    return 11;
  }
  if (tableLength > 12) {
    return 10;
  } else if (tableLength >= 9) {
    return 8.5;
  } else if (tableLength < 9) {
    return 6.6;
  }
};

const handleTableBanking = (
  key: any,
  doc: any,
  pdfHeader: any,
  nodes: any,
  startY: any,
  lastIndex: any,
  nodeLength: any,
  leftTableX: any,
  tableLength: any,
) => {
  const columnLength: number = pdfHeader.length;
  const pageWidth = doc.internal.pageSize.getWidth();
  const totalPagesExp = "{total_pages_count_string}";

  /* Create a new table. */
  doc.autoTable({
    columns: pdfHeader.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    showHead: key === "Summary" ? "never" : "everyPage",
    startY: startY,
    tableWidth: 90, // Full width minus margins

    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
      minCellHeight: 8, // Increase line height for larger table rows
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: {
        fontStyle: "bold",
        halign: "left",
        columnWidth: 90 / columnLength,
      },
      1: { halign: "center", columnWidth: 90 / columnLength },
      2: { halign: "center", columnWidth: 90 / columnLength },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,

    /* Use the green footer only for other tables, as there is no footer for a 
    breakdown of shifts, banking, and summary tables. */
    willDrawCell: function (data: any, index: any) {
      if (key !== "Breakdown of Shifts" && key !== "Summary") {
        const rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          doc.setFillColor(ExportPdfColor.green);
          doc.setTextColor(255, 255, 255);
        }
      }
    },
    /* Change the background color, font weight and alignment in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          if (data.cell.raw === "Currency") {
            data.cell.text = [""];
          }
        }
      }
      if (data.column.index === lastIndex) {
        data.cell.styles.halign = "right";
      }
      if (data.row.index !== 0) {
        if (nodeLength > 0) {
          if (data.row.index < nodeLength) {
            if (data.row.index % 2 === 0) {
              data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
              data.cell.styles.textColor = ["#ffffff"];
            } else {
              data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
              data.cell.styles.textColor = ["#ffffff"];
            }
          } else {
            if (
              data.row.raw.Date === "Pay In" ||
              data.row.raw.Date === "Pay Out"
            ) {
              data.cell.styles.fillColor = [ExportPdfColor.purple];
              data.cell.styles.textColor = ["#ffffff"];
              data.cell.styles.fontStyle = "bold";
            } else {
              data.cell.styles.fillColor = [ExportPdfColor.blueDark];
              data.cell.styles.textColor = ["#ffffff"];
              data.cell.styles.fontStyle = "bold";
            }
          }
        } else {
          if (data.row.index % 2 === 0) {
            data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          } else {
            data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          }
        }
      }
      if (
        data.row.index + 1 === rows.length &&
        key !== "Breakdown of Shifts" &&
        key !== "Pay In Out" &&
        key !== "Summary"
      ) {
        data.cell.styles.fontStyle = "bold";
      }
    },
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();

      /* If there is a image, insert it into the eatprestoLogoImg variable. */
      const eatprestoLogoImg = new Image();
      eatprestoLogoImg.src = eatprestoLogo;
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);

      doc.addImage(
        eatprestoLogoImg,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    margin: {
      top: 30,
      bottom: 30,
      left: leftTableX,
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
};

/* Create a pdf file */
export const handlePdf = (filterDetails: any, pdfHeader: any, nodes: any) => {
  const doc: any = new jsPDF("p");

  const today = moment().format("MMM_Do_YY").toString();

  /* If there is a image, insert it into the eatprestoLogoImg variable. */
  const eatprestoLogoImg = new Image();
  eatprestoLogoImg.src = eatprestoLogo;

  let startY = 65;

  const isObject = (value: any) => {
    return value !== null && typeof value === "object" && !Array.isArray(value);
  };

  /* Iterating table data */
  Object.entries(nodes).forEach(([key, value]: any) => {
    if (!key.includes("_tableName") && !_.isEmpty(value)) {
      // Get the number of columns in the table
      let lastIndex = isObject(value)
        ? value.payIn.length + value.payOut.length - 1
        : Object.values(value[0]).length - 1;
      if (key === "paid-in-out") {
        lastIndex = lastIndex - 1;
      }

      // If the height of the previous table is greater than 240, a new page is added.
      if (startY > 240) {
        doc.addPage();
        startY = 30;
      }
      if (key === "Banking" && !isObject(value)) {
        const { nodeLength } = handleNodeAndFooterLength(key, nodes);
        startY = startY + 8;
        handleConvertPdf(doc, filterDetails);
        doc.text(nodes[`${key}_tableName`], 14, startY);

        // Adjust the x position for the first table to align it to the left
        let leftTableX = 14; // Left side starting X position
        let rightTableX = 110; // Right side starting X position (adjust this as needed)
        const len = nodes["Banking_payInOut"].payIn.length>1 && nodes["Banking_payInOut"].payOut.length >1 ?6:5
        const tableLength =
          nodes["Banking_payInOut"].payIn.length +
          nodes["Banking_payInOut"].payOut.length +
          len;
        handleTableBanking(
          key,
          doc,
          pdfHeader[key],
          value,
          startY + 8,
          lastIndex,
          nodeLength,
          leftTableX,
          tableLength,
        );

        const bankingTableLength = doc.lastAutoTable.finalY + 13;

        doc.text("Cash Added" , 110, startY);

        handleTableCash(
          key,
          doc,
          [
            { title: "Type", field: "type" },
            { title: "Amount", field: "amount" },
          ],
          nodes["Banking_payInOut"].payIn,
          startY + 8,
          lastIndex,
          nodes["Banking_payInOut"].payIn.length,
          false,
        );

        doc.text("Cash Deducted" , 110, doc.lastAutoTable.finalY + 13);
        // startY = doc.lastAutoTable.finalY + 13;
        handleTableCash(
          key,
          doc,
          [
            { title: "Type", field: "type" },
            { title: "Amount", field: "amount" },
          ],
          nodes["Banking_payInOut"].payOut,
          doc.lastAutoTable.finalY + 13 + 8,
          lastIndex,
          nodes["Banking_payInOut"].payOut.length,
          true,
        );

        const cashDeductedY = doc.lastAutoTable.finalY + 10

        handleTableInfo(
          key,
          doc,
          [
            { title: "Type", field: "type" },
            { title: "Amount", field: "amount" },
          ],
          nodes["Banking_info"],
          bankingTableLength-4,
          lastIndex,
          nodes["Banking_info"].length,
          tableLength,
        );

        const totalY = doc.lastAutoTable.finalY + 4

        handleTableInfoTaking(
          key,
          doc,
          [
            { title: "Type", field: "type" },
            { title: "Amount", field: "amount" },
          ],
          nodes["Takings_info"],
          cashDeductedY,
          lastIndex,
          nodes["Takings_info"].length,
          tableLength,
        );
        startY =
        totalY;
      } else if (!isObject(value) && key !== "Banking_info" && key !== "Takings_info") {
        const { nodeLength } = handleNodeAndFooterLength(key, nodes);
        handleConvertPdf(doc, filterDetails);
        doc.text(nodes[`${key}_tableName`], 14, startY);
        handleTable(
          key,
          doc,
          pdfHeader[key],
          value,
          startY + 8,
          lastIndex,
          nodeLength,
        );
        startY = doc.lastAutoTable.finalY + 13;
      }
    }
  });

  doc.save(`shift_${today}_${Math.floor(100000 + Math.random() * 900000)}.pdf`);
};
