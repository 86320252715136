import moment from "moment";
import saveAs from "file-saver";
import _ from "lodash";
import { ExportExcelColor } from "../../../../../root/theme/exportColor";
import { convertDateTimeFormatInventory } from "../../../../../utils/ConvertDateTimeFormat";

/* Changing row colors */
const excelRawChangeColor = (index: number) => {
  if (index % 2 === 0) {
    return {
      argb: [ExportExcelColor.OddRowColor],
    };
  } else {
    return {
      argb: [ExportExcelColor.EvenRowColor],
    };
  }
};

const handleLogTable = (
  nodes: any,
  excelHeaders: any,
  worksheet: any,
  rowLength: any,
) => {
  let excelHeader: any = {};
  let excelData: any = {};
  /* Worksheet add headers */

  /* Find the last character in the excel */
  const len = excelHeaders.length + 64;
  let char = String.fromCharCode(len);

  /* Add a headers and change the header and empty row height. */
  excelHeaders.map((data: any) => {
    excelHeader[data.key] = data.header;
  });
  worksheet.addRow(excelHeader);

  /* Add a body in the excel. */
  nodes.map((data: any, index: number) => {
    Object.keys(excelHeader).map((header: any) => {
      excelData[header] = data[header];
    });
    worksheet.addRow(excelData);

    if (index === 0) {
      const row = worksheet.getRow(index + rowLength);
      row.eachCell(
        { includeEmpty: true },
        function (cell: any, colNumber: any) {
          let char = String.fromCharCode(64 + colNumber);
          worksheet.getCell(`A${index + rowLength})`).alignment = {
            vertical: "bottom",
            horizontal: "left",
          };
          worksheet.getCell(`${char}${index + rowLength})`).alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          if (6 === colNumber) {
            worksheet.getCell(`${char}${index + rowLength})`).alignment = {
              vertical: "bottom",
              horizontal: "right",
            };
          }

          // Change the font style in the specific cell.

          worksheet.getCell(`${char}${index + rowLength})`).font = {
            bold: true,
            color: { argb: "FFFFFF" },
          };
        },
      );

      // Change the height of the raw
      worksheet.getRow(index + rowLength).height = 28;
    }

    // Change the table body alignment, and first column font weight in the cell.
    const row = worksheet.getRow(index + rowLength + 1);
    row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
      let char = String.fromCharCode(64 + colNumber);
      let charNew = String.fromCharCode(64 + colNumber + 1);
      worksheet.getCell(`A${index + rowLength + 1})`).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };
      worksheet.getCell(`${charNew}${index + rowLength + 1})`).alignment = {
        vertical: "bottom",
        horizontal: "center",
      };
      if (6 === colNumber) {
        worksheet.getCell(`${char}${index + rowLength + 1})`).alignment = {
          vertical: "bottom",
          horizontal: "right",
        };
      }
    });

    worksheet.getRow(index + rowLength + 1).height = 28;
  });

  /* Change the footer height */
  worksheet.getRow(nodes.length + 3).height = 28;

  /* Change the title style */
  worksheet.addConditionalFormatting({
    ref: "A1",
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { bold: true, size: 10 },
        },
      },
    ],
  });

  /* Change the sub title stile */
  worksheet.addConditionalFormatting({
    ref: `B1:${char}1`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: {
            bold: true,
            size: 10,
          },
        },
      },
    ],
  });

  /* Change all row colors. */
  nodes.map((data: any, index: any) => {
    worksheet.addConditionalFormatting({
      ref: `A${rowLength + index + 1}:${char}${rowLength + index + 1}`,
      rules: [
        {
          type: "expression",
          formulae: ["MOD(2,2)=0"],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: excelRawChangeColor(rowLength + index + 1),
            },
            font: { color: { argb: "FFFFFF" } },
          },
        },
      ],
    });
  });

  /* Bold the letters in the first column. */
  const column = worksheet.getColumn(1);
  column.style = { font: { bold: true } };

  worksheet.addConditionalFormatting({
    ref: `A${rowLength}:${char}${rowLength}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: {
              argb: ExportExcelColor.HeaderRowColor,
            },
            font: { color: { argb: "ffffff" } },
          },
          font: {
            bold: false,
            color: {
              argb: "ffffff",
            },
          },
        },
      },
    ],
  });
};

/* Create new table */
const handleTable = (
  nodes: any,
  excelHeaders: any,
  worksheet: any,
  rowLength: any,
  nodeLength: any,
  footerLength: any,
) => {
  let excelHeader: any = {};
  let excelData: any = {};
  /* Worksheet add headers */

  /* Find the last character in the excel */
  const len = excelHeaders.length + 64;
  let char = String.fromCharCode(len);

  /* Add a headers and change the header and empty row height. */
  excelHeaders.map((data: any) => {
    excelHeader[data.key] = data.header;
  });
  worksheet.addRow(excelHeader);

  /* Add a body in the excel. */
  nodes.map((data: any, index: number) => {
    Object.keys(excelHeader).map((header: any) => {
      excelData[header] = data[header];
    });

    worksheet.addRow(excelData);

    // Change the table header alignment, and font weight in the cell.
    if (index === 0) {
      const row = worksheet.getRow(index + rowLength);
      row.eachCell(
        { includeEmpty: true },
        function (cell: any, colNumber: any) {
          let char = String.fromCharCode(64 + colNumber);
          worksheet.getCell(`A${index + rowLength})`).alignment = {
            vertical: "bottom",
            horizontal: "left",
          };
          worksheet.getCell(`${char}${index + rowLength})`).alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          if (6 === colNumber) {
            worksheet.getCell(`${char}${index + rowLength})`).alignment = {
              vertical: "bottom",
              horizontal: "right",
            };
          }

          // Change the font style in the specific cell.

          worksheet.getCell(`${char}${index + rowLength})`).font = {
            bold: true,
            color: { argb: "FFFFFF" },
          };
        },
      );

      // Change the height of the raw
      worksheet.getRow(index + rowLength).height = 28;
    }

    // Change the table body alignment, and first column font weight in the cell.
    const row = worksheet.getRow(index + rowLength + 1);
    row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
      let char = String.fromCharCode(64 + colNumber);
      let charNew = String.fromCharCode(64 + colNumber + 1);
      worksheet.getCell(`A${index + rowLength + 1})`).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };
      worksheet.getCell(`${charNew}${index + rowLength + 1})`).alignment = {
        vertical: "bottom",
        horizontal: "center",
      };
      if (6 === colNumber) {
        worksheet.getCell(`${char}${index + rowLength + 1})`).alignment = {
          vertical: "bottom",
          horizontal: "right",
        };
      }
    });

    worksheet.getRow(index + rowLength + 1).height = 28;
  });

  /* Change the footer height */
  worksheet.getRow(nodes.length + 3).height = 28;

  /* Change the title style */
  worksheet.addConditionalFormatting({
    ref: "A1",
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { bold: true, size: 10 },
        },
      },
    ],
  });

  /* Change the sub title stile */
  worksheet.addConditionalFormatting({
    ref: `B1:${char}1`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: {
            bold: true,
            size: 10,
          },
        },
      },
    ],
  });

  /* Change all row colors. */
  nodes.map((data: any, index: any) => {
    worksheet.addConditionalFormatting({
      ref: `A${rowLength + index + 1}:${char}${rowLength + index + 1}`,
      rules: [
        {
          type: "expression",
          formulae: ["MOD(2,2)=0"],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: excelRawChangeColor(rowLength + index + 1),
            },
            font: { color: { argb: "FFFFFF" } },
          },
        },
      ],
    });
  });

  /* Bold the letters in the first column. */
  const column = worksheet.getColumn(1);
  column.style = { font: { bold: true } };

  worksheet.addConditionalFormatting({
    ref: `A${rowLength}:${char}${rowLength}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: {
              argb: ExportExcelColor.HeaderRowColor,
            },
            font: { color: { argb: "ffffff" } },
          },
          font: {
            bold: false,
            color: {
              argb: "ffffff",
            },
          },
        },
      },
    ],
  });
};

/* Create a excel file */
export const handleExcel = (
  excelHeaders: any,
  nodes: any,
  filterDetails: any,
) => {
  const ExcelJS = require("exceljs");
  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet("Shift");

  // Change the first and second raw height
  worksheet.getRow(1).height = 28;
  worksheet.getRow(2).height = 28;
  worksheet.mergeCells("B1:F1");

  let filterOptions = "";

  /* Change of sub-title according to the number of locations.
  If the location is one, then that location is displayed, 
  if more than one location, the number of locations is displayed. */

  filterOptions =
    "Driver Sessions Summary Report for " +
    filterDetails.split("*")[1] +
    " Locations " +
    "Generated for the period of " +
    filterDetails.split("*")[0]; /* Add sub-title using filter options. */

  worksheet.getCell("B1").value = filterOptions;

  /* Add a empty row. */
  let rowLength = 3;
  nodes.map((driverDetails: any) => {
    if (driverDetails?.orders?.length > 0) {
      worksheet.columns = excelHeaders;
      worksheet.getCell("A1").value = "Driver Sessions report";
      worksheet.getCell("B1").value = filterOptions;
      worksheet.addRow({});
      worksheet.getRow(rowLength).height = 28;
      worksheet.getCell(`A${rowLength}`).value = driverDetails.cmsDriverName;
      worksheet.getCell(`A${rowLength}`).font = { bold: true };

      worksheet.getRow(rowLength).height = 28;
      rowLength = rowLength + 1;
      worksheet.getCell(`B${rowLength}`).alignment = { horizontal: "left" };
      worksheet.getCell(`A${rowLength}`).value = "Shift Start";
      worksheet.getCell(`B${rowLength}`).value = "Shift End";
      worksheet.getCell(`A${rowLength}`).font = { bold: false };
      worksheet.getCell(`B${rowLength}`).font = { bold: false };

      worksheet.getRow(rowLength).height = 28;
      rowLength = rowLength + 1;
      worksheet.getCell(`B${rowLength}`).alignment = { horizontal: "left" };
      worksheet.getCell(`A${rowLength}`).value = convertDateTimeFormatInventory(
        driverDetails.start,
      );
      worksheet.getCell(`B${rowLength}`).value = convertDateTimeFormatInventory(
        driverDetails.end,
      );
      worksheet.getCell(`A${rowLength}`).font = { bold: true };
      worksheet.getCell(`B${rowLength}`).font = { bold: true };

      worksheet.getRow(rowLength).height = 28;
      rowLength = rowLength + 1;
      worksheet.getCell(`B${rowLength}`).alignment = { horizontal: "left" };
      worksheet.getCell(`A${rowLength}`).value = `Logged Time: ${
        driverDetails.startRecorded
          ? convertDateTimeFormatInventory(driverDetails.startRecorded)
          : "-"
      }`;
      worksheet.getCell(`B${rowLength}`).value = `Logged Time: ${
        driverDetails.startRecorded
          ? convertDateTimeFormatInventory(driverDetails.endRecorded)
          : "-"
      }`;
      worksheet.getCell(`A${rowLength}`).font = { bold: true };
      worksheet.getCell(`B${rowLength}`).font = { bold: true };

      let counts = false;
      const logTableBody: any = [];
      driverDetails &&
        driverDetails?.log &&
        driverDetails?.log?.length > 0 &&
        driverDetails.log.map((logInfo: any) => {
          if (logInfo.type === "Clock Out" || logInfo.type === "Clock In") {
            if (!counts) {
              rowLength = rowLength + 1;
              worksheet.getRow(rowLength).height = 28;
              counts = true;
            }

            if (logInfo.type === "Clock In") {
              worksheet.getCell(`A${rowLength}`).value = logInfo.notes;
              worksheet.getCell(`A${rowLength}`).font = {
                bold: true,
                color: { argb: ExportExcelColor.red },
              };
            } else {
              worksheet.getCell(`B${rowLength}`).alignment = {
                horizontal: "left",
              };
              worksheet.getCell(`B${rowLength}`).value = logInfo.notes;
              worksheet.getCell(`B${rowLength}`).font = {
                bold: true,
                color: { argb: ExportExcelColor.red },
              };
            }
          } else {
            logInfo["date"] = convertDateTimeFormatInventory(logInfo.date);
            logTableBody.push(logInfo);
          }
        });
      if (logTableBody.length > 0) {
        rowLength = rowLength + 1;
        const header = ["type", "amount", "notes", "date"];

        rowLength = rowLength + 1;
        worksheet.getRow(rowLength).height = 28;
        worksheet.getCell(`A${rowLength}`).alignment = { horizontal: "left" };
        worksheet.getCell(`B${rowLength}`).alignment = { horizontal: "center" };
        worksheet.getCell(`C${rowLength}`).alignment = { horizontal: "center" };
        worksheet.getCell(`D${rowLength}`).alignment = { horizontal: "right" };
        worksheet.getCell(`A${rowLength}`).value = "Type";
        worksheet.getCell(`B${rowLength}`).value = "Amount";
        worksheet.getCell(`C${rowLength}`).value = "Notes";
        worksheet.getCell(`D${rowLength}`).value = "Date";
        worksheet.getCell(`A${rowLength}`).font = {
          bold: true,
          color: { argb: ExportExcelColor.white },
        };
        worksheet.getCell(`B${rowLength}`).font = {
          bold: true,
          color: { argb: ExportExcelColor.white },
        };
        worksheet.getCell(`C${rowLength}`).font = {
          bold: true,
          color: { argb: ExportExcelColor.white },
        };
        worksheet.getCell(`D${rowLength}`).font = {
          bold: true,
          color: { argb: ExportExcelColor.white },
        };
        worksheet.getCell(`A${rowLength}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: ExportExcelColor.HeaderRowColor }, // This sets the fill color to red
        };
        worksheet.getCell(`B${rowLength}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: ExportExcelColor.HeaderRowColor }, // This sets the fill color to red
        };
        worksheet.getCell(`C${rowLength}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: ExportExcelColor.HeaderRowColor }, // This sets the fill color to red
        };
        worksheet.getCell(`D${rowLength}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: ExportExcelColor.HeaderRowColor }, // This sets the fill color to red
        };

        logTableBody.map((data: any, index: any) => {
          rowLength = rowLength + 1;
          worksheet.getRow(rowLength).height = 28;
          worksheet.getCell(`A${rowLength}`).alignment = { horizontal: "left" };
          worksheet.getCell(`B${rowLength}`).alignment = {
            horizontal: "center",
          };
          worksheet.getCell(`C${rowLength}`).alignment = {
            horizontal: "center",
          };
          worksheet.getCell(`D${rowLength}`).alignment = {
            horizontal: "right",
          };
          worksheet.getCell(`A${rowLength}`).value = data["type"];
          worksheet.getCell(`B${rowLength}`).value = data["amount"];
          worksheet.getCell(`C${rowLength}`).value = data["notes"];
          worksheet.getCell(`D${rowLength}`).value = data["date"];
          worksheet.getCell(`A${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`B${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`C${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`D${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`A${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                index % 2 === 0
                  ? ExportExcelColor.EvenRowColor
                  : ExportExcelColor.OddRowColor,
            }, // This sets the fill color to red
          };
          worksheet.getCell(`B${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                index % 2 === 0
                  ? ExportExcelColor.EvenRowColor
                  : ExportExcelColor.OddRowColor,
            }, // This sets the fill color to red
          };
          worksheet.getCell(`C${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                index % 2 === 0
                  ? ExportExcelColor.EvenRowColor
                  : ExportExcelColor.OddRowColor,
            }, // This sets the fill color to red
          };
          worksheet.getCell(`D${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                index % 2 === 0
                  ? ExportExcelColor.EvenRowColor
                  : ExportExcelColor.OddRowColor,
            }, // This sets the fill color to red
          };
        });

        // handleLogTable(
        //   logTableBody,
        //   excelHeaders,
        //   worksheet,
        //   rowLength,
        // );
        rowLength = rowLength + logTableBody.length - 1;
      }

      worksheet.getRow(rowLength).height = 28;
      rowLength = rowLength + 1;
      worksheet.getCell(`B${rowLength}`).alignment = { horizontal: "left" };
      worksheet.getCell(`A${rowLength}`).value = "Cash Collect";
      worksheet.getCell(
        `B${rowLength}`,
      ).value = `£ ${driverDetails.cashCollect}`;
      worksheet.getCell(`A${rowLength}`).font = { bold: true };
      worksheet.getCell(`B${rowLength}`).font = { bold: true };

      worksheet.getRow(rowLength).height = 28;
      rowLength = rowLength + 1;
      worksheet.getCell(`B${rowLength}`).alignment = { horizontal: "left" };
      worksheet.getCell(`A${rowLength}`).value = "Delivery Wage";
      worksheet.getCell(`B${rowLength}`).value = `£ ${driverDetails.milageFee}`;
      worksheet.getCell(`A${rowLength}`).font = { bold: true };
      worksheet.getCell(`B${rowLength}`).font = { bold: true };

      worksheet.getRow(rowLength).height = 28;
      rowLength = rowLength + 1;
      worksheet.getCell(`B${rowLength}`).alignment = { horizontal: "left" };
      worksheet.getCell(`A${rowLength}`).value = "Daily Wage";
      worksheet.getCell(`B${rowLength}`).value = `£ ${(
        parseFloat(driverDetails.fixedFee) +
        parseFloat(driverDetails.adjustments)
      ).toFixed(2)}`;

      worksheet.getCell(`C${rowLength}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${rowLength}`).value = ` ${
        parseFloat(driverDetails.adjustments).toFixed(0) !== "0"
          ? "Adjustments"
          : ""
      }`;

      worksheet.getCell(`D${rowLength}`).alignment = { horizontal: "left" };
      worksheet.getCell(`D${rowLength}`).value = ` ${
        parseFloat(driverDetails.adjustments).toFixed(0) !== "0"
          ? parseFloat(driverDetails.adjustments).toFixed(2)
          : ""
      }`;

      worksheet.getCell(`A${rowLength}`).font = { bold: true };
      worksheet.getCell(`B${rowLength}`).font = { bold: true };
      worksheet.getCell(`C${rowLength}`).font = { bold: true };
      worksheet.getCell(`D${rowLength}`).font = { bold: true };

      worksheet.getRow(rowLength).height = 28;
      rowLength = rowLength + 1;
      worksheet.getCell(`B${rowLength}`).alignment = { horizontal: "left" };
      worksheet.getCell(`A${rowLength}`).value = "Total Wage";
      worksheet.getCell(`B${rowLength}`).value = `£ ${parseFloat(
        (
          parseFloat(driverDetails.fixedFee) +
          parseFloat(driverDetails.milageFee) +
          parseFloat(driverDetails.adjustments)
        ).toString(),
      ).toFixed(2)}`;
      // worksheet.getCell(`A${rowLength}`).value = "tableName";
      worksheet.getCell(`A${rowLength}`).font = { bold: true };
      worksheet.getCell(`B${rowLength}`).font = { bold: true };
      worksheet.getRow(rowLength).height = 28;
      let footerLength = 0;
      let nodeLength = 0;

      rowLength = rowLength + 1;

      if (driverDetails?.orders?.length > 0) {
        handleTable(
          driverDetails.orders,
          excelHeaders,
          worksheet,
          rowLength,
          nodeLength,
          footerLength,
        );
        rowLength = rowLength + driverDetails?.orders?.length + 3;
      } else {
        rowLength = rowLength + 1;
        worksheet.getCell(`A${rowLength}`).font = { bold: true };
        worksheet.getCell(`A${rowLength}`).value =
          "This driver has not orders.";
        rowLength = rowLength + 2;
      }
    }
  });

  const today = moment().format("MMM_Do_YY").toString();
  workbook.xlsx.writeBuffer().then(function (buffer: any) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `Driver_Sessions__Report${today}_${Math.floor(
        100000 + Math.random() * 900000,
      )}.xlsx`,
    );
  });
};
