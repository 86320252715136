import React, { useState } from "react";
import {
  Box,
  Grid,
  Hidden,
  createStyles,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import SkeleTonLoading from "../Loading/SkeleTonLoading";
import NoSalesFound from "../NoSalesFound";
import _ from "lodash";
import moment from "moment";
import PageHeaderMobile from "../PageHeader/PageHeaderMobile";
import FilterDriverSummary from "../FilterDriverSummary";
import SalePageTitleAndLocationDetails from "../SaleReport/SalePageTitleAndLocationDetails";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    avatarStyle: {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      width: "54px",
      height: "54px",
      marginLeft: "16px",
    },
    menuStyle: {
      padding: "20px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.imgBackground,
    },
    cardStyle: {
      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
    },
    categoryTitle: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      top: 194,
      zIndex: 1,
      fontSize: "20px",
      [theme.breakpoints.up("sm")]: {
        top: 200,
        paddingTop: "20px",
        fontSize: "32px",
      },
      [theme.breakpoints.up("1280")]: {
        fontSize: "32px",
        top: 62,
        paddingTop: "52px",
      },
    },
  }),
);

export interface paymentReportProps {
  getFilterData: any;
  isOpenSkeletonLoading: any;
  nodeList: any;
  topic: any;
  handleOnClickText: any;
  children: any;
  setOpenFilterCard: any;
  openFilterCard: any;
  filterDetails: any;
  setLocationSelectedList: any;
  locationSelectorList: any;
  getLocationSelectorTypingList: any;
  locationSelectedList: any;
  isGetLocationInfo: any;
  setFilterDetails: any;
}
/* Set the filter, sale title, sale sub title, and table using filter details */
const SaleReportOneDate: React.FunctionComponent<paymentReportProps> = ({
  getFilterData,
  isOpenSkeletonLoading,
  nodeList,
  topic,
  handleOnClickText,
  children,
  setOpenFilterCard,
  openFilterCard,
  filterDetails,
  setLocationSelectedList,
  locationSelectorList,
  getLocationSelectorTypingList,
  locationSelectedList,
  isGetLocationInfo,
  setFilterDetails,
}) => {
  const [filterDate, setFilterDate] = useState("");

  const maxWidth = useMediaQuery("(max-width: 600px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2600px)");
  const classes = useStyles();

  return (
    <>
      <Box>
        <Hidden lgUp>
          <PageHeaderMobile />
        </Hidden>

        <FilterDriverSummary
          setOpenFilterCard={setOpenFilterCard}
          openFilterCard={openFilterCard}
          getFilterData={getFilterData}
          setLocationSelectedList={setLocationSelectedList}
          locationSelectorList={locationSelectorList}
          getLocationSelectorTypingList={getLocationSelectorTypingList}
          locationSelectedList={locationSelectedList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
        />
        {!isOpenSkeletonLoading && !_.isEmpty(nodeList) ? (
          <Grid container spacing={2}>
            {/* {!_.isEmpty(menuNodeList) && ( */}
            <Grid item xs={12} sm={6}>
              {!isOpenSkeletonLoading && !_.isEmpty(nodeList) && (
                <SalePageTitleAndLocationDetails
                  topic={topic}
                  filterDate={filterDetails ? filterDetails.split("*")[0] : ""}
                  handleOnClickText={handleOnClickText}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          !isOpenSkeletonLoading &&
          !_.isEmpty(nodeList) && (
            <SalePageTitleAndLocationDetails
              topic={topic}
              filterDate={filterDetails}
              handleOnClickText={handleOnClickText}
            />
          )
        )}
        {!isOpenSkeletonLoading ? (
          <>
            {!_.isEmpty(nodeList) ? (
              <>{children}</>
            ) : (
              <NoSalesFound title={"Driver Details"} />
            )}
          </>
        ) : (
          <div style={{ marginTop: "80px" }}>
            <SkeleTonLoading isReport={true} />
          </div>
        )}
      </Box>
    </>
  );
};

export default SaleReportOneDate;
