import { Badge, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import FilterCard from "./FilterCard/FilterCard";
import _ from "lodash";
import TuneIcon from "@material-ui/icons/Tune";

import DefaultAlert from "../../alerts/DefaultAlert";
import { CustomTheme } from "../../../types/customTheme";
import moment from "moment";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  getFilterListFromArrayObjectAndArray,
  getLabelListFromArrayObjectAndArray,
} from "../../../utils/commonArrayMap";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    iconButton: {
      "&:hover": {
        background: "none",
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.background.entity_border}`,
      },
      backgroundColor: theme.palette.background.entity_background,
      marginBottom: "8px",
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
      border: `2px solid ${theme.palette.background.entity_border}`,
      textAlign: "center",
      width: "120px",
      height: "44px",
    },
    badgeFontColor: {
      "& .MuiBadge-badge": {
        color: "white",
      },
    },
  }),
);

export interface Props {
  setOpenFilterCard: any;
  openFilterCard: any;
  getFilterData: any;
  setLocationSelectedList: any;
  locationSelectorList: any;
  getLocationSelectorTypingList: any;
  locationSelectedList: any;
  isGetLocationInfo: any;
  setFilterDetails: any;
}

export const handleChangeFilterInOperator = (data: any) => {
  const newStr = data.toString().replace(/,/g, "*");
  return newStr;
};

export const handleFilterDataDetailsCustomer = (
  locationFilterList: any,
  createdStartDate: any,
) => {
  let location: any = "";
  let startDate: any = "";
  let allFilteredList: any = "";

  if (!_.isEmpty(locationFilterList)) {
    location = locationFilterList.join(", ");
  }

  if (createdStartDate) {
    startDate = moment(createdStartDate, "YYYY-MM-DD").format("YYYY-MM-DD");
  }

  allFilteredList = [location, startDate];

  const filtered = allFilteredList.filter((data: any) => !_.isEmpty(data));

  return filtered.join("*");
};

/* Enter the filter information to be sent for the 'where query' in the API call. */
export const handleFilterDataCustomer = (
  locationFilterList: any,
  dateFilterList: any,
) => {
  let location: any = "";
  let startDate: any = "";
  let allFilteredList: any = "";

  // If the locationFilterList is not empty, add the location details.
  if (!_.isEmpty(locationFilterList)) {
    // If the page is 'order', add the locationFilterList details to location.
    // If the page is not 'order', add the locationFilterList details to location.
    const newStr = locationFilterList.toString().replace(/,/g, ",");
    location =  newStr;
  }
  if (!_.isEmpty(dateFilterList)) {
    startDate = moment(dateFilterList).format("YYYY-MM-DD");
  }

  allFilteredList = [location, startDate];

  // Remove the empty data.
  const filtered = allFilteredList.filter((data: any) => data !== "");
  const filterCount = filtered.length;
  // If the page is 'order', Items in the filtered array connect by using ';'.

  const filterQuery = filtered.join(";");
  // If the page is not 'order', Items in the filtered array connect by using '1'.
  return { filterCount, filterQuery };
};

const FilterDriverSummary: React.FunctionComponent<Props> = ({
  setOpenFilterCard,
  openFilterCard,
  getFilterData,
  setLocationSelectedList,
  locationSelectorList,
  getLocationSelectorTypingList,
  locationSelectedList,
  isGetLocationInfo,
  setFilterDetails,
}) => {
  const { search } = useLocation();
  const params: any = new URLSearchParams(search);
  const [error, setError] = useState("");
  const [locationFilterList, setLocationFilterList] = useState([]);
  const [searchLocationName, setSearchLocationName] = useState([]);
  const [startDate, setStartDate] = useState<any>();

  const history = useHistory();
  const location = useLocation();
  const match: any = useRouteMatch();
  

  /**
   * Handles the selection of items for various filters and updates corresponding state variables.
   *
   * @param {string} selectorName - The name of the filter selector.
   * @param {Array} selectedList - The list of selected items for the filter.
   */
  const handleAllSelectedList = (selectorName: string, selectedList: any) => {
    // Create an array of selected item IDs.
    const selectedListIds = selectedList.map((item: any) => item.id);
    
    // Switch statement based on the dropdown selector name.
    switch (selectorName) {
      case "location":
        // Handle location filter.
        if (!_.isEmpty(selectedList)) {
          // Update location-related state variables.
          setLocationSelectedList(selectedList);
          setLocationFilterList(selectedListIds);
        } else if (_.isEmpty(selectedList)) {
          // No locations selected, get current location.
          const filteredCurrentLocation = locationSelectorList.filter(
            (itm: any) => itm.id === match.params.locationId,
          );

          // Get current location id.
          const currentLocationId = filteredCurrentLocation.map(
            (selectObject: any) => selectObject.id,
          );

          // Update location-related state variables with current location.
          setLocationSelectedList(filteredCurrentLocation);
          setLocationFilterList(currentLocationId);
        }
        break;
      case "date":
        setStartDate(selectedList);
      default:
        break;
    }
  };

  const handleChangeCreatedDateRange = (date: any) => {
    setStartDate(moment(date).format("YYYY-MM-DD"));
  };

  /* On initial load, modify the data in the filter according to the query parameters. */
  useEffect(() => {
    const id: any = params.get("locationId");
    const startDate: any = params.get("startDate");

    /* When there is no location id in the query parameters, 
    the corresponding location and this month are entered for the query parameters. */
    if ((_.isEmpty(id) || _.isEmpty(startDate)) && isGetLocationInfo) {
      const filteredCurrentLocation = locationSelectorList.filter(
        (itm: any) => itm.id === match.params.locationId,
      );
      handleChangeCreatedDateRange(new Date());
      setLocationSelectedList(filteredCurrentLocation);
      handleAllSelectedList("location", filteredCurrentLocation);
    } else if (!_.isEmpty(params.toString()) && isGetLocationInfo) {
      if (id !== null) {
        const idList = [...id.split(",")];
        const filteredLocationArray = getFilterListFromArrayObjectAndArray(
          locationSelectorList,
          idList,
        );
        if (filteredLocationArray.length === 1) {
        }
        setLocationSelectedList(filteredLocationArray);
        handleAllSelectedList("location", filteredLocationArray);
      }

      if (startDate !== null) {
        handleChangeCreatedDateRange(new Date(startDate));
      }
    }
  }, [isGetLocationInfo]);

  /* Remove empty query params */
  const handleRemoveEmptyQuery = (queryParam: any) => {
    const params = new URLSearchParams(queryParam);
    let keysForDel: any = [];
    params.forEach((value, key) => {
      if (value === "" || value === null) {
        keysForDel.push(key);
      }
    });
    keysForDel.forEach((key: any) => {
      params.delete(key);
    });
    return params;
  };

  /* Update query params when change the state */
  const handleUpdateQueryParams = () => {
    const queryParam = {
      locationId: locationFilterList.toString(),
      startDate: startDate ? startDate : "",
    };
    const params = handleRemoveEmptyQuery(queryParam);
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  /* Change the state after changing a query params. */
  const handleQueryParm = () => {
    const locationId: any = params.get("locationId");
    const startDate: any = params.get("startDate");

    let arrLocation: any = [];
    if (!_.isEmpty(locationSelectorList) && !_.isEmpty(locationId)) {
      let idList = [...locationId.split(",")];
      arrLocation = getLabelListFromArrayObjectAndArray(
        locationSelectorList,
        idList,
      );
    }

    let uniqueLocation = [...new Set(arrLocation)];

    const filterDetails = handleFilterDataDetailsCustomer(
      uniqueLocation,
      startDate,
    );

    setFilterDetails(filterDetails);
  };

  useEffect(() => {
    if (params?.size === 0) {
      handleFilterRequest();
    }
    handleQueryParm();
  }, [params]);

  const handleFilterRequest = () => {
    // Check if location info needs to be fetched.
    if (isGetLocationInfo) {
      handleUpdateQueryParams();
    }
    // Perform filtering and obtain filter count and query.
    let { filterCount, filterQuery } = handleFilterDataCustomer(
      locationFilterList,
      startDate,
    );
    // Adjust filter count if certain conditions are met.
    if (isGetLocationInfo && !_.isEmpty(filterQuery)) {
      // Adjust filter count based on specific filters.

      // Fetch filter data based on the current filter query.
      getFilterData(filterQuery);
    }
  };

  /* The values ​​to be filtered are set as query parameters and provided that data to the API. */
  useEffect(() => {
    handleFilterRequest();
  }, [locationFilterList, startDate]);

  /* Open and close filter card */
  const handleChangeFilterCard = () => {
    setOpenFilterCard(!openFilterCard);
  };

  const classes = useStyles();

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "start", marginTop: "16px" }}
      >
        <Badge
          badgeContent={2}
          color="secondary"
          className={classes.badgeFontColor}
        >
          <IconButton
            className={classes.iconButton}
            onClick={handleChangeFilterCard}
            disabled={!isGetLocationInfo}
          >
            <Typography style={{ display: "flex", alignItems: "center" }}>
              <TuneIcon fontSize="small" />
            </Typography>
            <Typography style={{ marginLeft: "8px", letterSpacing: "1px" }}>
              Filters
            </Typography>
          </IconButton>
        </Badge>
      </div>
      {openFilterCard && (
        <FilterCard
          value={startDate}
          locationSelectorList={locationSelectorList}
          handleAllSelectedList={handleAllSelectedList}
          getLocationSelectorTypingList={getLocationSelectorTypingList}
          searchLocationName={searchLocationName}
          setSearchLocationName={setSearchLocationName}
          locationSelectedList={locationSelectedList}
          handleChangeCreatedDateRange={handleChangeCreatedDateRange}
        />
      )}
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default FilterDriverSummary;
