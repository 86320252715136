import {
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  Box,
  makeStyles,
  createStyles,
  TextField,
  Divider,
} from "@material-ui/core";
import {
  createMuiTheme,
  ThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CloseIcon from "@material-ui/icons/Close";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React, { useState, useEffect } from "react";
import CardCommon from "../../../card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MultipleSelectorTextField from "../../selector/MultipleSelectorTextField";
import moment from "moment";
import { isSameDay } from "date-fns";
import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
import { BorderAll, BorderTop, BorderTopOutlined } from "@material-ui/icons";
import TextfieldCommon from "../../../textField/TextfieldCommon";

const useStyles = makeStyles((theme: CustomTheme) => ({
  customDividerNotOpenSidebarItem: {
    backgroundColor: theme.palette.grey[600],
    height: "0.8px",
    borderRadius: "10px",
    width: "100%",
  },
  buttonTextField: {
    backgroundColor: theme.palette.background.entity_highlight_background,
    color: theme.palette.custom.orange.contrastText,
    borderRadius: "10px",
    border: "none",
    padding: "10px 16px", // Add padding to make it look like a button
    textAlign: "left",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover, // Optional hover effect
    },
    "&:focus": {
      outline: "none",
    },
  },
  dateRangeIcon: {
    marginTop: "8px",
    fontSize: "24px",
  },
  closeIcon: {
    fontSize: "24px",
  },
  buttonStyle: {
    width: "200px",
    justifyContent: "space-between",
    display: "flex",
    height: "60px",
    marginTop: "6px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    backgroundColor: theme.palette.background.entity_highlight_background,
    "&:hover": {
      backgroundColor: theme.palette.background.entity_highlight_background,
    },
    padding: "6px 12px",
  },
  startDateStyle: {
    paddingTop: "10px",
    fontSize: "14px",
  },
  iconButton: {
    "&:hover": {
      background: "none",
    },
  },
}));

const CustomTextField = (startDate: any, open: any, setOpen: any) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.buttonStyle}
      size={"large"}
      variant="outlined"
      fullWidth
      onClick={() => !open && setOpen(true)}
    >
      <div>
        <div style={{ display: "flex" }}>
          <Typography className={classes.startDateStyle}>
            {moment(startDate).format("YYYY-MM-DD")}
          </Typography>
        </div>
      </div>
      <span>
        {open ? (
          <Typography style={{ fontWeight: "bold" }}>
            <CloseIcon
              className={classes.closeIcon}
              style={{ marginTop: "8px" }}
              onClick={() => setOpen(false)}
            />
          </Typography>
        ) : (
          <Typography>
            <DateRangeIcon className={classes.dateRangeIcon} />
          </Typography>
        )}
      </span>
    </Button>
  );
};

// Utility functions to get 'Today' and 'Yesterday' dates
const getToday = () => new Date();
const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

// Custom Toolbar with Today/Yesterday buttons
const CustomToolbar = (props: {
  onDateChange: (date: Date | null) => void;
}) => {
  const { onDateChange } = props;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
    >
      <Typography variant="h6">Select Date</Typography>
      <Box>
        <Button
          variant="outlined"
          onClick={() => onDateChange(getToday())}
          style={{ marginRight: "8px" }}
        >
          Today
        </Button>
        <Button variant="outlined" onClick={() => onDateChange(getYesterday())}>
          Yesterday
        </Button>
      </Box>
    </Box>
  );
};

export interface Props {
  value: any;
  locationSelectorList: any;
  handleAllSelectedList: any;
  getLocationSelectorTypingList: any;
  searchLocationName: any;
  setSearchLocationName: any;
  locationSelectedList: any;
  handleChangeCreatedDateRange: any;
}

const FilterCard: React.FunctionComponent<Props> = ({
  value,
  locationSelectorList,
  handleAllSelectedList,
  getLocationSelectorTypingList,
  searchLocationName,
  setSearchLocationName,
  locationSelectedList,
  handleChangeCreatedDateRange,
}) => {
  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  const [selectedDate, setSelectedDate] = useState<any>(value);

  // Create a new theme based on the existing one
  const muiTheme = createMuiTheme({
    ...theme, // Spread the existing theme
    overrides: {
      ...theme.overrides, // Retain existing overrides
      MuiPaper: {
        root: {
          backgroundColor: theme.palette.background.entity_highlight_background,
        },
      },
      MuiPopover: {
        paper: {
          borderRadius: "10px", // Apply borderRadius to the popover component
          marginTop: "4px",
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: "10px", // Apply to the root element of the outlined input
          backgroundColor: theme.palette.background.entity_highlight_background,
          "&:hover": {
            backgroundColor:
              theme.palette.background.entity_highlight_background,
          },
          "&:hover $notchedOutline": {
            borderColor: theme.palette.background.entity_border, // Disable border on hover
          },
        },
        input: {
          borderRadius: "10px", // Apply to the input field
        },
        notchedOutline: {
          borderColor: theme.palette.background.entity_border, // Remove the border for outlined input
        },
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          backgroundColor: theme.palette.background.entity_highlight_background,
          "&:hover": {
            backgroundColor:
              theme.palette.background.entity_highlight_background,
          },
        },
      },
    },
  });

  // Ensure selectedDate is updated when value prop changes
  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  // Handle date change from both picker and toolbar
  const handleDateChange = (newValue: Date | null) => {
    setSelectedDate(newValue); // Update internal state
    handleChangeCreatedDateRange(newValue); // Propagate to parent component
    setOpen(false); // Close DatePicker
  };

  const [open, setOpen] = useState<boolean>(false); // State to control the DatePicker

  const handleToday = () => {
    handleDateChange(new Date());
  };

  const handleYesterday = () => {
    handleDateChange(moment().subtract(1, "days").toDate());
  };

  return (
    <div style={{ marginBottom: "32px" }}>
      <CardCommon backgroundColor={"entity_background"}>
        <div style={{ margin: "12px" }}>
          <CardCommon backgroundColor={"entity_highlight_background"}>
            <Grid container>
              <Grid item xs={12} style={{ padding: "6px 0px 14px 2px" }}>
                <MultipleSelectorTextField
                  selectorList={locationSelectorList}
                  handleSelectedList={handleAllSelectedList}
                  getLocationSelectorTypingList={getLocationSelectorTypingList}
                  searchLocationName={searchLocationName}
                  setSearchLocationName={setSearchLocationName}
                  selectedList={locationSelectedList}
                  multiple={false}
                />
              </Grid>
            </Grid>
          </CardCommon>
        </div>
        <div style={{ margin: "12px" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                padding: "6px 0px 14px 2px",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <div>
                <Typography style={{ fontWeight: "bold", marginBottom: "2px" }}>
                  Date
                </Typography>

                <ThemeProvider theme={muiTheme}>
                  <DatePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                    label=""
                    format="yyyy/MM/dd"
                    variant="inline"
                    inputVariant="outlined"
                    autoFocus={false}
                    orientation="landscape"
                    open={open} // Control open state
                    onOpen={() => setOpen(true)} // Open DatePicker
                    onClose={() => setOpen(false)} // Close DatePicker
                    maxDate={new Date()}
                    // TextFieldComponent={(props) => (
                    //   <TextField
                    //     {...props}
                    //     style={{ position: "relative" }}
                    //     onChange={() => !open && setOpen(true)}
                    //     variant="outlined"
                    //     autoFocus={false}
                    //   >
                    //     eee
                    //   </TextField>
                    // )}
                    PopoverProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton className={classes.iconButton}>
                            {open ? (
                              <Typography style={{ fontWeight: "bold" }}>
                                <CloseIcon
                                  className={classes.closeIcon}
                                  style={{ marginTop: "8px" }}
                                  onClick={() => setOpen(false)}
                                />
                              </Typography>
                            ) : (
                              <Typography>
                                <DateRangeIcon
                                  className={classes.dateRangeIcon}
                                />
                              </Typography>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    renderDay={(
                      date: any,
                      selectedDate,
                      isInCurrentMonth,
                      dayComponent,
                    ) => {
                      const today = new Date();
                      const isToday = isSameDay(date, today);

                      return React.cloneElement(dayComponent, {
                        style: {
                          ...(isToday
                            ? {
                                textDecoration: "underline",
                                color: theme.palette.custom.orange.contrastText,
                              }
                            : {}),
                          cursor: "pointer", // Optional: Add pointer cursor
                        },
                      });
                    }}
                    ToolbarComponent={({
                      date,
                      onChange,
                      ...other
                    }: ToolbarComponentProps) => (
                      <div
                        style={{
                          borderRight: `1px solid ${theme.palette.grey[600]}`,
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          backgroundColor:
                            theme.palette.background
                              .entity_highlight_background,
                        }}
                      >
                       
                        <div style={{ display: "block", marginTop: "8px" }}>
                          <div>
                            <Button
                              onClick={handleYesterday}
                              style={{
                                color: theme.palette.custom.orange.contrastText,
                                textTransform: "none",
                                width: "120px",
                                height: "40px",
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <Typography
                                align="left"
                                style={{
                                  fontSize: "12px",
                                  paddingLeft: "8px",
                                  fontWeight:
                                    selectedDate ===
                                    moment(
                                      moment().subtract(1, "days").toDate(),
                                    ).format("YYYY-MM-DD")
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                Yesterday
                              </Typography>
                            </Button>
                          </div>
                          <Divider
                            className={classes.customDividerNotOpenSidebarItem}
                          />
                          <div>
                            <Button
                              onClick={handleToday}
                              style={{
                                color: theme.palette.custom.orange.contrastText,
                                textTransform: "none",
                                width: "120px",
                                height: "40px",
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <Typography
                                align="left"
                                style={{
                                  fontSize: "12px",
                                  paddingLeft: "8px",
                                  fontWeight:
                                    selectedDate ===
                                    moment(new Date()).format("YYYY-MM-DD")
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                Today
                              </Typography>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </ThemeProvider>
              </div>
            </Grid>
          </Grid>
        </div>
      </CardCommon>
    </div>
  );
};

export default FilterCard;

// import React, { useState } from "react";
// import { DatePicker } from "@material-ui/pickers";
// import { Button, useTheme } from "@material-ui/core";
// import moment from "moment";
// import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
// import { isSameDay } from "date-fns";
// import { CustomTheme } from "../../../../types/customTheme";

// export interface Props {
//   value: any;
//   locationSelectorList: any;
//   handleAllSelectedList: any;
//   getLocationSelectorTypingList: any;
//   searchLocationName: any;
//   setSearchLocationName: any;
//   locationSelectedList: any;
//   handleChangeCreatedDateRange: any;
// }

// const FilterCard: React.FunctionComponent<Props> = ({
//   value,
//   locationSelectorList,
//   handleAllSelectedList,
//   getLocationSelectorTypingList,
//   searchLocationName,
//   setSearchLocationName,
//   locationSelectedList,
//   handleChangeCreatedDateRange,
// }) => {
//   const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
//   const [open, setOpen] = useState<boolean>(false); // State to control the DatePicker

//   const handleDateChange = (date: Date | null) => {
//     setSelectedDate(date);
//     setOpen(false); // Close DatePicker
//   };

//   const handleToday = () => {
//     setSelectedDate(new Date());
//     setOpen(false); // Close DatePicker
//   };

//   const handleYesterday = () => {
//     setSelectedDate(moment().subtract(1, "days").toDate());
//     setOpen(false); // Close DatePicker
//   };

//   const theme: CustomTheme = useTheme();

//   return (
//     <div style={{ display: 'flex', alignItems: 'center' }}>

//     <DatePicker
//       value={selectedDate}
//       onChange={handleDateChange}
//       label="Select Date"
//       format="MM/dd/yyyy"
//       variant="inline"

//       inputVariant="outlined"
//       open={open} // Control open state
//       onOpen={() => setOpen(true)} // Open DatePicker
//       onClose={() => setOpen(false)} // Close DatePicker
//       maxDate={new Date()}
//       renderDay={(date: any, selectedDate, isInCurrentMonth, dayComponent) => {
//         const today = new Date();
//         const isToday = isSameDay(date, today);

//         return React.cloneElement(dayComponent, {
//           style: {
//             ...(isToday
//               ? {
//                   textDecoration: "underline",
//                   color: theme.palette.custom.orange.contrastText,
//                 }
//               : {}),
//             cursor: "pointer", // Optional: Add pointer cursor
//           },
//         });
//       }}

//       ToolbarComponent={({
//         date,
//         onChange,
//         ...other
//       }: ToolbarComponentProps) => (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             padding: "0 16px",
//           }}
//         >
//           <div style={{ display: "flex", alignItems: "center" }}>
//             <Button
//               onClick={handleYesterday}
//               style={{
//                 marginRight: "8px",
//                 color: theme.palette.custom.orange.contrastText,
//                 textTransform: "none"
//               }}
//             >
//               Yesterday
//             </Button>
//             <Button
//               onClick={handleToday}
//               style={{ color: theme.palette.custom.orange.contrastText, textTransform: "none" }}
//             >
//               Today
//             </Button>
//           </div>
//         </div>
//       )}
//     />
//     </div>
//   );
// };

// export default FilterCard;
