import React, { useEffect, useState } from "react";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import GetAppIcon from "@material-ui/icons/GetApp";
import WithLoading from "../../../../utils/WithLoading";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { CustomTheme } from "../../../../types/customTheme";
import { handlePdf } from "./convert/handlePdf";
import { handleExcel } from "./convert/handleExcel";
import CardCommon from "../../../../components/card/CardCommon";
import moment from "moment";
import {
  convertDateFormat,
  convertDateTimeFormat,
  convertDateTimeFormatInventory,
} from "../../../../utils/ConvertDateTimeFormat";
import { useRouteMatch } from "react-router-dom";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import DriverSessionBody from "./DriverSessionBody";

export interface ShiftHeaderProps {
  isLoading: any;
  driverSessions: any;
  filterDetails: any;
  filterData: any;
  locationSelectorList: any;
  setOpenFilterCard: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    startTextStyle: {
      display: "flex",
      justifyContent: "start",
      paddingLeft: "16px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: "8px",
      },
    },
    endTextStyle: {
      display: "flex",
      justifyContent: "end",
      paddingRight: "16px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: "8px",
      },
    },
  }),
);

/**
 * DriverSessionsHeader Component:
 *
 * This component handles the header section of the Shift component, including
 * sorting controls, location selection, and shift generation. It displays shift
 * information in a table-like format with sortable columns and provides options
 * for selecting shifts, generating reports, and handling user interactions.
 */
const DriverSessionsHeader: React.FunctionComponent<ShiftHeaderProps> = ({
  isLoading,
  driverSessions,
  filterDetails,
  filterData,
  locationSelectorList,
  setOpenFilterCard,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);

  useEffect(() => {
    const list: any = [];
    let abc: any = [];
    let abc1: any = {};
    const cloneDriverSessions = _.cloneDeep(driverSessions);
    if (cloneDriverSessions.length > 0) {
      cloneDriverSessions.map((item: any) => {
        const list: any = [];
        if (item?.orders) {
          item.orders.map((data: any) => {
            list.push({
              customerName: data.customerName,
              address: data.address,
              paymentType:
                data.paymentType === "cash"
                  ? `£ ${parseFloat(data.paymentAmount).toFixed(2)}`
                  : "PAID",
              driverEarning: parseFloat(data.driverEarning).toFixed(2),
              drivingDistance: `${parseFloat(data.drivingDistance).toFixed(
                2,
              )} Miles`,
            });
          });
        }

        item["orders"] = list;
        if (item.cmsDriverName === "UNASSIGNED") {
          abc1 = item;
        } else {
          abc.push(item);
        }
      });
      abc.push(abc1);
    }
    setPdfHeaders([
      { title: "Customer Name", field: "customerName" },
      { title: "Address", field: "address" },
      { title: "Payment Type", field: "paymentType" },
      { title: "Payment Amount", field: "paymentAmount" },
      { title: "Driver Earning", field: "driverEarning" },
      { title: "Driving Distance", field: "drivingDistance" },
    ]);

    setNodesPdfExcel(abc);
    setExcelHeaders([
      { header: "Customer Name", key: "customerName", width: 50, height: 68 },
      { header: "Address", key: "address", width: 50, height: 68 },
      { header: "Payment Type", key: "paymentType", width: 50, height: 68 },
      { header: "Payment Amount", key: "paymentAmount", width: 50, height: 68 },
      { header: "Driver Earning", key: "driverEarning", width: 50, height: 68 },
      {
        header: "Driving Distance",
        key: "drivingDistance",
        width: 50,
        height: 68,
      },
    ]);
  }, [driverSessions]);

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const theme: CustomTheme = useTheme();
  const open = Boolean(anchorEl);
  const match: any = useRouteMatch();
  const locationId = match.params.locationId;
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <LocationDetailsCard
            locationSelectedList={
              filterDetails ? [{ label: filterDetails.split("*")[0] }] : []
            }
            handleOnClickText={handleOnClickText}
          />
          {nodesPdfExcel.length > 0 && (
            <div style={{ width: "140px" }}>
              <MenuButtonCommon
                id="basic-button"
                variant="contained"
                style={{
                  fontSize: 11,
                  height: "38px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                color="blue"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <GetAppIcon />
                <Typography
                  variant="body1"
                  style={{ paddingLeft: "16px", color: "white" }}
                >
                  Export
                </Typography>
              </MenuButtonCommon>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  style: {
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  },
                }}
                style={{ top: "56px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handlePdf(pdfHeaders, nodesPdfExcel, filterDetails);
                    handleClose();
                  }}
                >
                  Export as PDF
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleExcel(excelHeaders, nodesPdfExcel, filterDetails);
                    handleClose();
                  }}
                >
                  Export as CSV
                </MenuItem>
              </Menu>
            </div>
          )}
        </Grid>
        {nodesPdfExcel &&
          nodesPdfExcel.map(
            (driverDetails: any) =>
              driverDetails?.orders.length > 0 &&
              driverDetails.cmsDriverName !== "ALL" && (
                <DriverSessionBody driverDetails={driverDetails} />
              ),
          )}
      </Grid>
    </>
  );
};

export default WithLoading(DriverSessionsHeader);
